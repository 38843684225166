import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { Layout } from "../components/Layout/index";

const PolicyPage: React.VFC = () => {
  return (
    <Layout title="ご利用規約" description="ミスギのご利用規約ページです。">
      <Breadcrumb labels={[{ url: "", text: "ご利用規約" }]} />
      <div className="l-container-sm mb-32 mt-16">
        <h1 className="text-2xl font-bold">ご利用規約</h1>
        <p className="mt-10 text-xl font-medium">
          当サイトのご利用にあたって、 必ずご一読ください。
        </p>
        <dl>
          <div className="mt-14">
            <dt className="border-b border-solid border-[#E5E5E5] pb-6 text-2xl font-bold text-primary">
              著作権
            </dt>
            <dd className="mt-8 text-base">
              本サイト内のコンテンツ(文章、画像・資料等)に関する著作権は、当社または原著作者その他の権利者に帰属します。
              個人的な利用を目的とする場合、その他著作権法により認められる場合を除き、コンテンツを当社、原著作者またはその他の権利者の許諾を得ることなく、複製、改変、販売、送信など使用することは著作権法により禁止されています。コンテンツを使用する場合には、事前に当社の許諾を得るようお願いいたします。
            </dd>
          </div>
          <div className="mt-20">
            <dt className="border-b border-solid border-[#E5E5E5] pb-6 text-2xl font-bold text-primary">
              商標権
            </dt>
            <dd className="mt-8 text-base">
              本サイト内に掲載される個々の商標・ロゴマーク、商号に関する権利は、当社または個々の権利の所有者に帰属します。
              これらを当社または個々の権利の所有者の許諾を得ることなく使用する行為等は商標法等により禁止されています。
            </dd>
          </div>
          <div className="mt-20">
            <dt className="border-b border-solid border-[#E5E5E5] pb-6 text-2xl font-bold text-primary">
              免責事項
            </dt>
            <dd className="mt-8 text-base">
              当社は、コンテンツの内容について、正確性、有用性、確実性、安全性その他いかなる保証もいたしません。
              万一、コンテンツのご利用により何らかの損害が発生した場合であっても、当社は、一切の責任を負いかねます。
              当社は、コンテンツやURLを予告なしに変更または削除することがあり、また、本サイトの運営を予告なしに中断または中止することがあります。
            </dd>
          </div>
          <div className="mt-20">
            <dt className="border-b border-solid border-[#E5E5E5] pb-6 text-2xl font-bold text-primary">
              リンクについて
            </dt>
            <dd className="mt-8 text-base">
              本サイトへのリンクを希望される場合は、事前に当社へご連絡ください。
            </dd>
          </div>
          <div className="mt-20">
            <dt className="border-b border-solid border-[#E5E5E5] pb-6 text-2xl font-bold text-primary">
              プライバシーポリシー
            </dt>
            <dd className="mt-8 text-base">
              当社は、お客様の個人情報をご提供いただく場合、個人情報に関して適用される法令、規範を遵守するとともに下記の個人情報保護方針に従います。
              当社は、個人情報（氏名や住所、メールアドレスのような個人を特定できる情報）がお客様にとっての貴重なプライバシー情報であることを認識しており、個人情報保護法および関連するその他の法令・規範を尊重することをお約束いたします。
              当社は、個人情報をお客様に明示した利用目的の範囲内で取り扱います。
              当社は、お客様より収集させていただいた個人情報を適切に管理し、お客様の同意がある場合または正当な理由がある場合を除き、第三者に開示または提供しません。
              当社は、個人情報への不正アクセス、個人情報の紛失、破壊、改ざんおよび漏えい等の予防に努めます。
            </dd>
          </div>
        </dl>
      </div>
    </Layout>
  );
};

export default PolicyPage;
